// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-careers-index-js": () => import("./../../../src/pages/about-us/careers/index.js" /* webpackChunkName: "component---src-pages-about-us-careers-index-js" */),
  "component---src-pages-about-us-contact-us-index-js": () => import("./../../../src/pages/about-us/contact-us/index.js" /* webpackChunkName: "component---src-pages-about-us-contact-us-index-js" */),
  "component---src-pages-about-us-ethics-and-compliance-index-js": () => import("./../../../src/pages/about-us/ethics-and-compliance/index.js" /* webpackChunkName: "component---src-pages-about-us-ethics-and-compliance-index-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-our-culture-index-js": () => import("./../../../src/pages/about-us/our-culture/index.js" /* webpackChunkName: "component---src-pages-about-us-our-culture-index-js" */),
  "component---src-pages-about-us-our-leadership-index-js": () => import("./../../../src/pages/about-us/our-leadership/index.js" /* webpackChunkName: "component---src-pages-about-us-our-leadership-index-js" */),
  "component---src-pages-about-us-senior-leadership-index-js": () => import("./../../../src/pages/about-us/senior-leadership/index.js" /* webpackChunkName: "component---src-pages-about-us-senior-leadership-index-js" */),
  "component---src-pages-funding-programs-external-funding-index-js": () => import("./../../../src/pages/funding-programs/external-funding/index.js" /* webpackChunkName: "component---src-pages-funding-programs-external-funding-index-js" */),
  "component---src-pages-funding-programs-investigator-sponsored-studies-index-js": () => import("./../../../src/pages/funding-programs/investigator-sponsored-studies/index.js" /* webpackChunkName: "component---src-pages-funding-programs-investigator-sponsored-studies-index-js" */),
  "component---src-pages-funding-programs-investigator-sponsored-studies-new-js": () => import("./../../../src/pages/funding-programs/investigator-sponsored-studies/new.js" /* webpackChunkName: "component---src-pages-funding-programs-investigator-sponsored-studies-new-js" */),
  "component---src-pages-funding-programs-medical-education-grants-index-js": () => import("./../../../src/pages/funding-programs/medical-education-grants/index.js" /* webpackChunkName: "component---src-pages-funding-programs-medical-education-grants-index-js" */),
  "component---src-pages-funding-programs-patient-advocacy-sponsorships-index-js": () => import("./../../../src/pages/funding-programs/patient-advocacy-sponsorships/index.js" /* webpackChunkName: "component---src-pages-funding-programs-patient-advocacy-sponsorships-index-js" */),
  "component---src-pages-funding-programs-patients-at-the-heart-index-js": () => import("./../../../src/pages/funding-programs/patients-at-the-heart/index.js" /* webpackChunkName: "component---src-pages-funding-programs-patients-at-the-heart-index-js" */),
  "component---src-pages-funding-programs-progress-at-the-heart-index-js": () => import("./../../../src/pages/funding-programs/progress-at-the-heart/index.js" /* webpackChunkName: "component---src-pages-funding-programs-progress-at-the-heart-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsroom-index-js": () => import("./../../../src/pages/newsroom/index.js" /* webpackChunkName: "component---src-pages-newsroom-index-js" */),
  "component---src-pages-newsroom-investors-index-js": () => import("./../../../src/pages/newsroom/investors/index.js" /* webpackChunkName: "component---src-pages-newsroom-investors-index-js" */),
  "component---src-pages-our-focus-index-js": () => import("./../../../src/pages/our-focus/index.js" /* webpackChunkName: "component---src-pages-our-focus-index-js" */),
  "component---src-pages-our-products-index-js": () => import("./../../../src/pages/our-products/index.js" /* webpackChunkName: "component---src-pages-our-products-index-js" */),
  "component---src-pages-patients-educational-resources-index-js": () => import("./../../../src/pages/patients/educational-resources/index.js" /* webpackChunkName: "component---src-pages-patients-educational-resources-index-js" */),
  "component---src-pages-patients-index-js": () => import("./../../../src/pages/patients/index.js" /* webpackChunkName: "component---src-pages-patients-index-js" */),
  "component---src-pages-patients-research-focus-areas-index-js": () => import("./../../../src/pages/patients/research-focus-areas/index.js" /* webpackChunkName: "component---src-pages-patients-research-focus-areas-index-js" */),
  "component---src-pages-privacy-policy-terms-of-use-index-js": () => import("./../../../src/pages/privacy-policy-terms-of-use/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-terms-of-use-index-js" */),
  "component---src-pages-science-index-js": () => import("./../../../src/pages/science/index.js" /* webpackChunkName: "component---src-pages-science-index-js" */),
  "component---src-pages-sitemap-index-js": () => import("./../../../src/pages/sitemap/index.js" /* webpackChunkName: "component---src-pages-sitemap-index-js" */),
  "component---src-templates-bod-js": () => import("./../../../src/templates/bod.js" /* webpackChunkName: "component---src-templates-bod-js" */),
  "component---src-templates-press-release-js": () => import("./../../../src/templates/PressRelease.js" /* webpackChunkName: "component---src-templates-press-release-js" */)
}

